import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useAvisos from '../hooks/useAviso';
import { usePerfilContexto } from '../contextos/PerfilContexto';
import { Perfil } from '../tipos/Perfil';

const API_URL = process.env.REACT_APP_API_URL + '/usuarios';

const paginasRedirect = ['/', '/login', '/criar-conta', '/recuperar-senha'];

export function usePerfis() {
  const { perfil, setPerfil } = usePerfilContexto();
  const [loading, setLoading] = useState<boolean>(false);
  const [salvando, setSalvando] = useState<boolean>(false);
  const navigate = useNavigate();
  const { adicionarAviso } = useAvisos();

  // Função para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem('token_legify');
  const authenticated = () => !!getToken();

  const autoLogin = async () => {
    const token = getToken();
    if (!token) {
      navigate('/');
      return;
    }

    if (loading) {
      return;
    }

    try {
      setLoading(true);

      // Decodifica o token para verificar validade
      const payload = JSON.parse(atob(token.split('.')[1])); // Decodifica o payload do JWT
      const exp = payload.exp * 1000; // Converte para milissegundos

      if (Date.now() >= exp) {
        console.warn('Token expirado');
        localStorage.removeItem('token_legify');
        navigate('/');
        return;
      }

      const response = await axios.get(`${API_URL}/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setPerfil({ ...response.data, carregarContas: true, carregarCategorias: true, carregarCartoes: true });

      if (paginasRedirect.includes(window.location.pathname)) {
        navigate('/dashboard');
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Erro desconhecido.');
      }
      navigate('/'); // Redireciona caso ocorra erro na requisição
    } finally {
      setLoading(false);
    }
  };

  // Função para login
  const login = async (email: string, hash: string, primeiroAcesso: boolean) => {
    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}/login`, { email, hash });

      const { token, usuario } = response.data;

      // Armazena o token no localStorage
      localStorage.setItem('token_legify', token);

      // Define o perfil do usuário
      setPerfil({ ...usuario, carregarContas: true, carregarCategorias: true, carregarCartoes: true });

      if (primeiroAcesso) {
        navigate('/dashboard');
      } else {
        navigate('/dashboard');
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Erro desconhecido.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Função para criar um perfil (Autenticada)
  const createPerfil = async (newPerfil: Perfil, primeiroAcesso: boolean) => {
    setLoading(true);

    try {
      await axios.post(API_URL, newPerfil);
      login(newPerfil.email || '', newPerfil.hashCode || '', primeiroAcesso);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Erro desconhecido.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Função para atualizar um perfil (Autenticada)
  const updatePerfil = async (id: number, updatedPerfil: Perfil) => {
    if (!perfil || !perfil.id) {
      return;
    }

    setSalvando(true);

    const token = getToken(); // Obtém o token do localStorage

    if (!token) {
      adicionarAviso('Autenticação necessária.');
      setSalvando(false);
      return;
    }

    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedPerfil, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setPerfil({ ...perfil, ...response.data });

      adicionarAviso('Informações do perfil atualizados com sucesso!', 'sucesso');
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Erro desconhecido.');
      }
    } finally {
      setSalvando(false);
    }
  };

  // Função para deletar um perfil (Autenticada)
  const deletePerfil = async (id: number) => {
    setLoading(true);

    const token = getToken(); // Obtém o token do localStorage

    if (!token) {
      adicionarAviso('Autenticação necessária.');
      setLoading(false);
      return;
    }

    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Erro desconhecido.');
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    perfil,
    setPerfil,
    loading,
    salvando,
    authenticated,
    createPerfil,
    updatePerfil,
    deletePerfil,
    login
  };
}

