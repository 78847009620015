import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const firebaseConfig = {
  apiKey: 'AIzaSyC2A_FTZA1BH5hGkght433OreurWO9gKpY',
  authDomain: 'advogando-2a8ad.firebaseapp.com',
  databaseURL: 'https://advogando-2a8ad-default-rtdb.firebaseio.com',
  projectId: 'advogando-2a8ad',
  storageBucket: 'advogando-2a8ad.firebasestorage.app',
  messagingSenderId: '532218963232',
  appId: '1:532218963232:web:e493d6fe410a07a19b6345',
  measurementId: 'G-7NZ0P1HYD9'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

root.render(<App />);

