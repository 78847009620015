import { useState } from 'react';
import { app, analytics, auth } from '../index'; // Certifique-se de importar o auth corretamente
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword
} from 'firebase/auth';
import useAvisos from './useAviso';
import { usePerfis } from '../servicos/usePerfil';
import { useUsuarioContexto } from '../contextos/UsuarioContexto';

export default function useFirebase() {
  const [loading, setLoading] = useState(false);
  const googleProvider = new GoogleAuthProvider();
  const { adicionarAviso } = useAvisos();
  const { createPerfil, setPerfil } = usePerfis();
  const { setUsuario } = useUsuarioContexto();

  // Função para login com email e senha
  function loginWithCredentials(email: string, senha: string) {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, senha)
      .then((userCredential) => {
        return userCredential.user;
      })
      .catch((error) => {
        console.log(error);

        setLoading(false);
        if (error.code === 'auth/invalid-credential') {
          adicionarAviso('Usuário não encontrado. Verifique o e-mail ou a a senha informada.', 'erro');
        } else if (error.code === 'auth/invalid-email') {
          adicionarAviso('E-mail inválido. Verifique o formato do endereço de e-mail.', 'erro');
        } else {
          console.log(error);
          adicionarAviso('Ocorreu um erro ao realizar o seu login.', 'erro');
        }
      });
  }

  // Função para criação de conta com email e senha
  function register(email: string, senha: string) {
    setLoading(true);
    return createUserWithEmailAndPassword(auth, email, senha)
      .then((userCredential) => {
        setLoading(false);
        return userCredential.user;
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === 'auth/email-already-in-use') {
          adicionarAviso('E-mail já cadastrado. Tente fazer login ou recuperar a senha.', 'erro');
        } else if (error.code === 'auth/weak-password') {
          adicionarAviso('A senha é muito fraca. Use pelo menos 8 caracteres, incluindo letras e números.', 'erro');
        } else if (error.code === 'auth/invalid-email') {
          adicionarAviso('Formato de e-mail inválido. Por favor, insira um e-mail válido.', 'erro');
        } else if (error.code === 'auth/password-does-not-meet-requirements') {
          adicionarAviso(
            'A senha informada não atende aos requisitos de segurança. Certifique-se de que a senha tenha pelo menos 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.',
            'erro'
          );
        } else {
          adicionarAviso('Ocorreu um erro ao criar a sua conta.', 'erro');
        }
      });
  }

  // Função para login com Google (cria a conta automaticamente se não existir)
  function loginOrRegisterWithGoogle() {
    setLoading(true);
    return signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        const additionalInfo = getAdditionalUserInfo(result);

        if (additionalInfo?.isNewUser) {
          createPerfil(
            {
              nome: user.displayName ?? '',
              telefone: user.phoneNumber ?? '',
              email: user.email ?? '',
              hashCode: user.uid
            },
            true
          ).then(() => {
            return user;
          });
        } else {
          return user;
        }
      })
      .catch((error) => {
        setLoading(false);
        adicionarAviso('Ocorreu um erro ao realizar o seu login com o Google.', 'erro');
      });
  }

  // Função para recuperação de senha
  function resetPassword(email: string) {
    setLoading(true);

    return sendPasswordResetEmail(auth, email)
      .then(() => {
        adicionarAviso('E-mail de recuperação enviado. Verifique sua caixa de entrada.', 'info');
        setLoading(false);
        return true; // Retorna true se o email foi enviado com sucesso
      })
      .catch((error) => {
        setLoading(false);

        if (error.code === 'auth/invalid-email') {
          adicionarAviso('Formato de e-mail inválido. Verifique o endereço informado.', 'erro');
        } else if (error.code === 'auth/missing-email') {
          adicionarAviso('Por favor, informe um endereço de e-mail.', 'erro');
        } else {
          adicionarAviso('Ocorreu um erro ao enviar e-mail de recuperação de senha.', 'erro');
        }
      });
  }

  // Função para trocar a senha do usuário autenticado
  function changePassword(currentPassword: string, newPassword: string) {
    setLoading(true);

    const user = auth.currentUser; // Pega o usuário autenticado
    if (!user || !user.email) {
      setLoading(false);
      adicionarAviso('Usuário não autenticado', 'erro');
      throw new Error('Usuário não autenticado');
    }

    // Criar credencial com o email do usuário e a senha atual
    const credential = EmailAuthProvider.credential(user.email, currentPassword);

    // Reautenticar o usuário antes de permitir a troca de senha
    return reauthenticateWithCredential(user, credential)
      .then(() => {
        // Após reautenticar, atualiza a senha
        return updatePassword(user, newPassword)
          .then(() => {
            setLoading(false);
            adicionarAviso('Senha alterada com sucesso', 'sucesso');
            return true;
          })
          .catch((error) => {
            setLoading(false);
            if (error.code === 'auth/password-does-not-meet-requirements') {
              adicionarAviso('A senha deve conter um caractere minúsculo, um caractere maiúsculo e um numero', 'erro');
            } else {
              adicionarAviso('Erro ao alterar a senha', 'erro');
            }
          });
      })
      .catch((error) => {
        console.error('Erro na reautenticação:', error.message);
        setLoading(false);

        if (error.code === 'auth/wrong-password') {
          adicionarAviso('Senha atual incorreta', 'erro');
        } else {
          adicionarAviso('Erro ao reautenticar o usuário', 'erro');
        }
      });
  }

  async function logout() {
    localStorage.removeItem('token_legify');
    setPerfil({});
    setUsuario({});
    return await auth.signOut();
  }

  return { loading, auth, loginWithCredentials, changePassword, register, loginOrRegisterWithGoogle, resetPassword, logout };
}

