import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';

type Props = {
  markers: google.maps.LatLngLiteral[];
};

const Mapa = ({ markers }: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: 'AIzaSyBvbenr0bRlEV3eVIwyd6pZ_XmzQAJJHCc'
  });

  const [userPosition, setUserPosition] = useState<{ lat: number; lng: number } | null>(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      },
      (error) => {
        console.error('Erro ao obter localização:', error);
      }
    );
  }, []);

  if (!isLoaded) return <div>Carregando mapa...</div>;

  const primary = getComputedStyle(document.documentElement).getPropertyValue('--primary').trim();
  const mapStreet = getComputedStyle(document.documentElement).getPropertyValue('--mapStreet').trim();
  const mapGeo = getComputedStyle(document.documentElement).getPropertyValue('--mapGeo').trim();
  const mapWater = getComputedStyle(document.documentElement).getPropertyValue('--mapWater').trim();
  const textColor = getComputedStyle(document.documentElement).getPropertyValue('--text').trim();
  const placeColor = getComputedStyle(document.documentElement).getPropertyValue('--placeholder').trim();
  const borderColor = getComputedStyle(document.documentElement).getPropertyValue('--border').trim();

  const modeStyle: google.maps.MapTypeStyle[] = [
    {
      elementType: 'labels.text.fill',
      stylers: [{ color: placeColor }]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ color: borderColor }]
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: mapGeo }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: mapStreet }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: mapWater }]
    },
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }]
    }
  ];

  return (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={userPosition || { lat: -23.5505, lng: -46.6333 }} // Default to São Paulo
      zoom={12}
      options={{
        styles: modeStyle,
        disableDefaultUI: true,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      }}
    >
      <Marker
        position={userPosition || { lat: -23.5505, lng: -46.6333 }} // Default to São Paulo
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 4,
          fillColor: '#1570EF',
          fillOpacity: 1,
          strokeWeight: 8,
          strokeColor: '#1570EF20'
        }}
      />

      {/* Advogados: pin customizado azul menor */}
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={marker}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 7,
            fillColor: primary,
            fillOpacity: 1,
            strokeWeight: 8,
            strokeColor: textColor + 20
          }}
        />
      ))}
    </GoogleMap>
  );
};

export default Mapa;

