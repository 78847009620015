import { useState } from 'react';
import TextField from '../../../componentes/inputs/TextField';
import styles from './FormularioLogin.module.css';
import useAvisos from '../../../hooks/useAviso';
import Google from '../../../images/google.svg';
import useFirebase from '../../../hooks/useFirebase';
import { usePerfis } from '../../../servicos/usePerfil';
import Botao from '../../../componentes/Botao';

type FormularioLoginProps = {
  onCriarConta: () => void;
  onRecuperarSenha: () => void;
};

export default function FormularioLogin({ onCriarConta, onRecuperarSenha }: FormularioLoginProps) {
  const [dados, setDados] = useState({ email: '', senha: '' });

  const { login } = usePerfis();
  const { loading, loginWithCredentials, loginOrRegisterWithGoogle } = useFirebase();
  const { adicionarAviso } = useAvisos();

  const handleLogin = async () => {
    if (dados.email === '' || dados.senha === '') {
      adicionarAviso('Por favor, preencha os campos de e-mail e senha.', 'alerta');
      return;
    }

    const user = await loginWithCredentials(dados.email, dados.senha);

    if (user) {
      await login(user.email ?? dados.email, user.uid, false);
    }
  };

  const handleLoginGoogle = async () => {
    const user = await loginOrRegisterWithGoogle();

    if (user) {
      await login(user.email ?? '', user.uid, false);
    }
  };

  return (
    <div className={styles.formulario}>
      <TextField label="E-mail" value={dados.email} onChange={(email) => setDados({ ...dados, email: email })} />
      <TextField label="Senha" value={dados.senha} onChange={(senha) => setDados({ ...dados, senha: senha })} password />
      <div className={styles.opcoes}>
        <div className={styles.opcao} onClick={onRecuperarSenha}>
          <span>Esqueci minha senha</span>
        </div>
      </div>
      <Botao titulo={'Entrar'} icone={'check'} onClick={handleLogin} carregando={loading} type="primary" />
      <div className={styles.provedores}>
        <span>ou</span>
        <Botao titulo={'Entrar com o Google'} icone="google" onClick={handleLoginGoogle} type="provider" />
      </div>
      <span className={styles.hint}>
        Não tem uma conta?{' '}
        <span className={styles.opcao} onClick={onCriarConta}>
          Criar conta
        </span>
      </span>
    </div>
  );
}

