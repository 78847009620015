import { createContext, useContext } from 'react';
import { Perfil } from '../tipos/Perfil';

type PerfilContextType = {
  perfil: Perfil;
  setPerfil: React.Dispatch<React.SetStateAction<Perfil>>;
};

export const PerfilContexto = createContext<PerfilContextType | undefined>(undefined);

export const usePerfilContexto = () => {
  const context = useContext(PerfilContexto);
  if (!context) {
    throw new Error('usePerfilContexto deve ser usado dentro de um PerfilContexto.Provider');
  }
  return context;
};

