import Google from '../imagens/google.svg';

type Props = {
  icone?: string;
  titulo: string;
  carregando?: boolean;
  onClick: () => void;
  type: 'primary' | 'secondary' | 'cancel' | 'provider';
};

const Botao = (props: Props) => {
  const color =
    props.type === 'primary'
      ? 'var(--white)'
      : props.type === 'secondary'
        ? 'var(--primary)'
        : props.type === 'provider'
          ? 'var(--text)'
          : 'var(--textSecondary)';
  const border =
    props.type === 'primary'
      ? 'var(--primary)'
      : props.type === 'secondary'
        ? 'var(--primary)'
        : props.type === 'provider'
          ? 'var(--text)'
          : 'var(--border)';
  const bg =
    props.type === 'primary'
      ? 'var(--primary)'
      : props.type === 'secondary'
        ? 'var(--background)'
        : props.type === 'provider'
          ? 'var(--background)'
          : 'var(--background)';

  const handleClick = () => {
    if (props.carregando) return;
    props.onClick();
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4px',
        height: '34px',
        cursor: 'pointer',
        borderRadius: '8px',
        padding: '7px 10px',
        fontWeight: '600',
        fontSize: '14px',
        background: bg,
        border: `1px solid ${border}`,
        color: color,
        userSelect: 'none'
      }}
      onClick={handleClick}
    >
      {props.icone !== 'google' ? (
        <span className={`material-symbols-rounded ${props.carregando && 'spin'}`}>
          {props.carregando ? 'progress_activity' : props.icone}
        </span>
      ) : (
        <img src={Google} alt="Botão" style={{ width: '16px', height: '16px', paddingRight: '4px' }} />
      )}
      {!props.carregando && <span>{props.titulo}</span>}
    </div>
  );
};

export default Botao;

