import { useState, useEffect } from 'react';
import styles from './InputBase.module.css';

const MoneyField = (props: {
  label?: string;
  value?: number;
  onChange?: (value: number) => void;
  placeholder?: string;
  icone: string;
  disabled?: boolean;
  currency?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  obrigatorio?: boolean;
}) => {
  const { label, value, onChange, placeholder, icone, disabled, currency = 'BRL', obrigatorio } = props;

  const formatCurrency = (amount?: number) => {
    if (amount === undefined || amount === null) return '';
    return new Intl.NumberFormat('pt-BR', {
      currency,
      minimumFractionDigits: 2
    }).format(amount / 100);
  };

  const parseCurrency = (str: string) => {
    const numericValue = parseFloat(str.replace(/[^\d]/g, ''));
    return isNaN(numericValue) ? 0 : numericValue;
  };

  const [rawValue, setRawValue] = useState<number | undefined>(value !== undefined ? value * 100 : obrigatorio ? 0 : undefined);
  const [displayValue, setDisplayValue] = useState<string>(
    value !== undefined ? formatCurrency(value * 100) : obrigatorio ? formatCurrency(0) : ''
  );

  useEffect(() => {
    if (value !== undefined) {
      const cents = value * 100;
      setRawValue(cents);
      setDisplayValue(formatCurrency(cents));
    } else {
      if (obrigatorio) {
        setRawValue(0);
        setDisplayValue(formatCurrency(0));
      } else {
        setRawValue(undefined);
        setDisplayValue('');
      }
    }
  }, [value, obrigatorio]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const rawInput = e.target.value.replace(/\D/g, '');

    if (rawInput === '') {
      if (obrigatorio) {
        setRawValue(0);
        setDisplayValue(formatCurrency(0));
        if (onChange) onChange(0);
      } else {
        setRawValue(undefined);
        setDisplayValue('');
        if (onChange) onChange(undefined as unknown as number);
      }
      return;
    }

    const numericValue = parseCurrency(rawInput);

    if (numericValue < 0) return;

    setRawValue(numericValue);
    setDisplayValue(formatCurrency(numericValue));
    if (onChange) onChange(numericValue / 100);
  };

  return (
    <div className={styles.container} style={{ gap: label ? '6px' : '0px', ...props.style }}>
      <div className={styles.labelContainer}>
        <span className={styles.label}>{label}</span>
        {obrigatorio && <span className={styles.obrigatorio}>*</span>}
      </div>
      <div className={styles.wrapper} onClick={props.onClick}>
        <span className={`material-symbols-rounded ${styles.icone}`}>{icone}</span>
        <input
          type="text"
          className={styles.input}
          value={displayValue}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default MoneyField;

