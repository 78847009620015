import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Principal from './telas/Principal';
import Master from './telas/componentes/Master';
import { AvisoContexto } from './contextos/AvisoContexto';
import { Aviso } from './tipos/Aviso';
import { Perfil } from './tipos/Perfil';
import { PerfilContexto } from './contextos/PerfilContexto';
import { Usuario } from './tipos/Usuario';
import { UsuarioContexto } from './contextos/UsuarioContexto';

function App() {
  const [avisos, setAvisos] = useState<Aviso[]>([]);
  const [perfil, setPerfil] = useState<Perfil>({});
  const [usuario, setUsuario] = useState<Usuario>({});

  useEffect(() => {
    const root = document.documentElement;
    const tema = localStorage.getItem('tema');
    root.setAttribute('data-theme', tema || 'light');
  }, []);

  return (
    <BrowserRouter>
      <AvisoContexto.Provider value={{ avisos, setAvisos }}>
        <UsuarioContexto.Provider value={{ usuario, setUsuario }}>
          <PerfilContexto.Provider value={{ perfil, setPerfil }}>
            <Master>
              <Routes>
                <Route path="/" element={<Principal />} />
              </Routes>
            </Master>
          </PerfilContexto.Provider>
        </UsuarioContexto.Provider>
      </AvisoContexto.Provider>
    </BrowserRouter>
  );
}

export default App;

