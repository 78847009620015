import Botao from '../../componentes/Botao';
import PopUp from '../../componentes/popup/PopUp';
import Switch from '../../componentes/switch/Switch';
import useAvisos from '../../hooks/useAviso';
import Logo from '../../imagens/Logo';
import { useNavigate } from 'react-router-dom';
import Login from '../login/Login';
import CriarConta from '../login/criarConta/CriarConta';
import RecuperarSenha from '../login/recuperarSenha/RecuperarSenha';
import { useState } from 'react';

type MenuProps = {
  titulo: string;
  rota: string;
};

const Menu = ({ titulo, rota }: MenuProps) => {
  const navigator = useNavigate();
  const menuAtual = window.location.pathname;

  const isActive = menuAtual === rota;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        cursor: 'pointer',
        borderRadius: '8px',
        padding: '8px 12px',
        fontWeight: 'bold',
        color: isActive ? 'var(--text)' : 'var(--text-secondary)',
        background: isActive ? 'var(--secondary)' : 'transparent'
      }}
      onClick={() => navigator(rota)}
    >
      <span>{titulo}</span>
    </div>
  );
};

const Header = () => {
  const { adicionarAviso } = useAvisos();
  const [mostrarLogin, setMostrarLogin] = useState(false);
  const [mostrarCriarConta, setMostrarCriarConta] = useState(false);
  const [mostrarRecuperarSenha, setMostrarRecuperarSenha] = useState(false);

  const handleAparencia = (value: string) => {
    const root = document.documentElement;
    root.setAttribute('data-theme', value);
    localStorage.setItem('tema', value);
    window.location.reload();
  };

  const handleLogin = () => {
    setMostrarRecuperarSenha(false);
    setMostrarCriarConta(false);
    setMostrarLogin(true);
  };

  const handleCreateAccount = () => {
    setMostrarRecuperarSenha(false);
    setMostrarLogin(false);
    setMostrarCriarConta(true);
  };

  const handleRecuperarSenha = () => {
    setMostrarLogin(false);
    setMostrarCriarConta(false);
    setMostrarRecuperarSenha(true);
  };

  const tema = localStorage.getItem('tema');

  return (
    <header
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'var(--background)',
        padding: '12px 10vw',
        borderBottom: '1px solid var(--border)',
        color: 'var(--text)'
      }}
    >
      {mostrarLogin && (
        <PopUp titulo="Bem vindo!" subtitulo="Informe seus dados para continuar" onClose={() => setMostrarLogin(false)}>
          <Login onCriarConta={handleCreateAccount} onRecuperarSenha={handleRecuperarSenha} />
        </PopUp>
      )}
      {mostrarCriarConta && (
        <PopUp titulo="Criar conta" subtitulo="Informe seus dados para continuar" onClose={() => setMostrarCriarConta(false)}>
          <CriarConta onClick={handleLogin} />
        </PopUp>
      )}
      {mostrarRecuperarSenha && (
        <PopUp
          titulo="Recuperar senha"
          subtitulo="Informe seu e-mail para recuperar a senha"
          onClose={() => setMostrarRecuperarSenha(false)}
        >
          <RecuperarSenha onClick={handleLogin} />
        </PopUp>
      )}
      <div
        style={{ display: 'flex', alignItems: 'center', gap: '4px', fontWeight: 'bold', cursor: 'pointer' }}
        onClick={() => window.location.reload()}
      >
        <Logo style={{ width: '36px', height: '36px', fill: 'var(--primary)' }} />
        <span>Legify</span>
      </div>
      <div style={{ display: 'flex', gap: '8px' }}>
        <Menu titulo="Advogados" rota="/" />
      </div>
      <div style={{ display: 'flex', gap: '32px', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span className={'material-symbols-rounded'}>{tema === 'dark' ? 'dark_mode' : 'light_mode'}</span>
          <Switch checked={tema === 'dark'} onChange={(value) => handleAparencia(value ? 'dark' : 'light')} />{' '}
        </div>
        <Botao titulo="Entrar" icone="login" type="primary" onClick={handleLogin} />
      </div>
    </header>
  );
};

export default Header;

