import { createContext, useContext } from 'react';
import { Aviso } from '../tipos/Aviso';

type AvisoContextType = {
  avisos: Aviso[];
  setAvisos: React.Dispatch<React.SetStateAction<Aviso[]>>;
};

export const AvisoContexto = createContext<AvisoContextType | undefined>(undefined);

export const useAvisoContexto = () => {
  const context = useContext(AvisoContexto);
  if (!context) {
    throw new Error('useAvisoContexto deve ser usado dentro de um AvisoContexto.Provider');
  }
  return context;
};

