import Botao from '../componentes/Botao';
import TipoAdvogado from '../tipos/Advogado';
import Facebook from '../imagens/facebook.svg';
import Instagram from '../imagens/instagram.svg';
import LinkedIn from '../imagens/linkedin.svg';
import Twitter from '../imagens/twitter.svg';
import Abas from '../componentes/abas/Abas';
import { useState } from 'react';

type Props = {
  dados: TipoAdvogado;
  modal?: boolean;
  onClose?: () => void;
};

const Advogado = ({ dados, modal, onClose }: Props) => {
  const [abaSelecionada, setAbaSelecionada] = useState('Sobre');

  const handleCurtir = () => {
    // Aqui você pode adicionar a lógica para curtir o advogado
    console.log(`Curtindo ${dados.nome}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        padding: modal ? '0 32px' : '0',
        maxHeight: modal ? 'calc(70vh)' : '',
        overflowY: modal ? 'auto' : 'hidden',
        minWidth: modal ? '1100px' : '',
        maxWidth: modal ? '1100px' : ''
      }}
    >
      {!modal && (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '8px' }}>
          <span style={{ cursor: 'pointer', color: 'var(--textSecondary)' }} onClick={onClose}>
            Advogados
          </span>
          <span className="material-symbols-rounded" style={{ color: 'var(--textSecondary)', fontSize: '12px' }}>
            chevron_right
          </span>
          <span style={{ cursor: 'pointer' }}>{dados.nome}</span>
        </div>
      )}
      <div id="cabecalho" style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
          <div id="titulo" style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            <div id="imagem" style={{ width: '64px', height: '64px', borderRadius: '50%' }}>
              <img src={dados.imagem} alt={dados.nome} style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover' }} />
            </div>
            <div id="info" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '4px' }}>
              <span style={{ fontSize: '16px', fontWeight: 600 }}>{dados.nome}</span>
              <span
                style={{ fontSize: '14px', color: 'var(--textSecondary)' }}
              >{`${dados.endereco.rua} - ${dados.endereco.bairro} - ${dados.endereco.cidade}`}</span>
            </div>
          </div>
          <div id="acoes">
            <Botao icone="favorite" titulo="Curtir" onClick={handleCurtir} type="primary" />
          </div>
        </div>

        <div style={{ display: 'flex', gap: '32px' }}>
          {/* Detalhes */}
          <div id="detalhes-infos" style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            {dados.disponivel_agora && (
              <span style={{ color: 'var(--text)', display: 'flex', gap: '4px', alignItems: 'center' }}>
                <span className="material-symbols-rounded" style={{ fontSize: '14px', color: 'var(--success)' }}>
                  radio_button_checked
                </span>
                Disponível agora
              </span>
            )}
            <span style={{ color: 'var(--textSecondary)', display: 'flex', gap: '4px', alignItems: 'center' }}>
              <span className="material-symbols-rounded" style={{ fontSize: '14px' }}>
                location_on
              </span>
              {dados.distancia_km} km de você
            </span>
            <span style={{ color: 'var(--textSecondary)', display: 'flex', gap: '4px', alignItems: 'center' }}>
              <span className="material-symbols-rounded" style={{ fontSize: '14px' }}>
                {dados.atende_online ? 'wifi' : 'home'}
              </span>
              {dados.atende_online ? 'Atende online' : 'Atende presencialmente'}
            </span>
          </div>
        </div>
      </div>
      <Abas abas={['Sobre', 'Avaliações']} abaSelecionada={abaSelecionada} setAbaSelecionada={setAbaSelecionada} />
      {abaSelecionada === 'Sobre' && (
        <div style={{ display: 'flex', gap: '48px', paddingBottom: '16px' }}>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '32px' }}>
            {/* Sobre o profissional */}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                paddingBottom: '32px',
                borderBottom: '1px solid var(--border)'
              }}
            >
              <span style={{ fontSize: '14px', color: 'var(--text)', fontWeight: 600 }}>Sobre o profissional</span>
              <span style={{ fontSize: '14px', color: 'var(--textSecondary)', marginTop: '8px', whiteSpace: 'pre-line' }}>
                {dados.descricao}
              </span>
            </div>

            {/* Serviços prestados */}
            {dados.servicos && dados.servicos.length > 0 && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <span style={{ fontSize: '14px', color: 'var(--text)', fontWeight: 600 }}>Serviços e preços</span>
                <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  {dados.servicos?.map((servico, index) => (
                    <div
                      key={index}
                      style={{
                        padding: '12px 16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        borderRadius: '8px',
                        border: '1px solid var(--border)',
                        backgroundColor: 'var(--light-gray)'
                      }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                        <span style={{ fontSize: '14px', color: 'var(--text)', fontWeight: 600 }}>{servico.nome}</span>
                        <span style={{ fontSize: '14px', color: 'var(--text)', fontWeight: 500 }}>
                          Apartir de {servico.preco ? `R$ ${servico.preco.toFixed(2).replace('.', ',')}` : '-'}
                        </span>
                      </div>
                      <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>{servico.descricao}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div style={{ flex: 0, display: 'flex', flexDirection: 'column', gap: '32px', minWidth: '360px', maxWidth: '360px' }}>
            {/* Informações de contato */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <span style={{ fontSize: '14px', color: 'var(--text)', fontWeight: 600 }}>Informações</span>
              <div style={{ display: 'grid', gap: '16px', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <Informacao titulo="Cidade" valor={dados.endereco.cidade} onClick={() => window.open(dados.site, '_blank')} />
                <Informacao titulo="Site" valor={dados.site} onClick={() => window.open(dados.site, '_blank')} />
                <Informacao titulo="Telefone" valor={dados.telefone} onClick={() => window.open(`tel:${dados.telefone}`, '_blank')} />
                <Informacao titulo="Email" valor={dados.email} onClick={() => window.open(`mailto:${dados.email}`, '_blank')} />
              </div>
            </div>
            {/* Redes Sociais */}
            {(dados.redes_sociais?.facebook ||
              dados.redes_sociais?.instagram ||
              dados.redes_sociais?.linkedin ||
              dados.redes_sociais?.twitter) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}
              >
                <span style={{ fontSize: '14px', color: 'var(--text)', fontWeight: 600 }}>Redes sociais</span>
                <div style={{ display: 'flex', gap: '16px' }}>
                  {dados.redes_sociais?.facebook && (
                    <img
                      src={Facebook}
                      alt="Facebook"
                      style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                      onClick={() => window.open(dados.redes_sociais?.facebook, '_blank')}
                    />
                  )}
                  {dados.redes_sociais?.instagram && (
                    <img
                      src={Instagram}
                      alt="Instagram"
                      style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                      onClick={() => window.open(dados.redes_sociais?.instagram, '_blank')}
                    />
                  )}
                  {dados.redes_sociais?.linkedin && (
                    <img
                      src={LinkedIn}
                      alt="LinkedIn"
                      style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                      onClick={() => window.open(dados.redes_sociais?.linkedin, '_blank')}
                    />
                  )}
                  {dados.redes_sociais?.twitter && (
                    <img
                      src={Twitter}
                      alt="X"
                      style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                      onClick={() => window.open(dados.redes_sociais?.twitter, '_blank')}
                    />
                  )}
                </div>
              </div>
            )}
            {/* Horarios de funcionamento */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}
            >
              <span style={{ fontSize: '14px', color: 'var(--text)', fontWeight: 600 }}>Horário de atendimento</span>
              <div style={{ display: 'grid', gap: '16px', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>Segunda</span>
                  <span style={{ fontSize: '14px', color: 'var(--text)' }}>{dados.horario_funcionamento?.segunda || '-'}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>Terça</span>
                  <span style={{ fontSize: '14px', color: 'var(--text)' }}>{dados.horario_funcionamento?.terca || '-'}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>Quarta</span>
                  <span style={{ fontSize: '14px', color: 'var(--text)' }}>{dados.horario_funcionamento?.quarta || '-'}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>Quinta</span>
                  <span style={{ fontSize: '14px', color: 'var(--text)' }}>{dados.horario_funcionamento?.quinta || '-'}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>Sexta</span>
                  <span style={{ fontSize: '14px', color: 'var(--text)' }}>{dados.horario_funcionamento?.sexta || '-'}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>Sábado</span>
                  <span style={{ fontSize: '14px', color: 'var(--text)' }}>{dados.horario_funcionamento?.sabado || '-'}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>Domingo</span>
                  <span style={{ fontSize: '14px', color: 'var(--text)' }}>{dados.horario_funcionamento?.domingo || '-'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {abaSelecionada === 'Avaliações' && (
        <div style={{ display: 'flex', gap: '32px', paddingBottom: '16px' }}>
          <span style={{ fontSize: '14px', color: 'var(--text)', fontWeight: 600 }}>Avaliações</span>
        </div>
      )}
    </div>
  );
};

type InformacaoProps = {
  titulo: string;
  valor?: string;
  onClick?: () => void;
};

const Informacao = ({ titulo, valor, onClick }: InformacaoProps) => {
  const handleClick = () => {
    if (onClick && valor) {
      onClick();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', maxWidth: '160px' }}>
      <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>{titulo}</span>
      <span
        style={{
          fontSize: '14px',
          color: 'var(--text)',
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          cursor: onClick && valor ? 'pointer' : 'default'
        }}
        onClick={handleClick}
      >
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '200px',
            display: 'inline-block'
          }}
        >
          {valor || '-'}
        </span>
        {valor && onClick && (
          <span className="material-symbols-rounded" style={{ fontSize: '14px', color: 'var(--textSecondary)' }}>
            north_east
          </span>
        )}
      </span>
    </div>
  );
};

export default Advogado;

