import { useEffect, useMemo, useState } from 'react';
import useAvisos from '../../hooks/useAviso';
import Header from './Header';
import { Aviso as AvisoType } from '../../tipos/Aviso';

type Props = {
  children: React.ReactNode;
};

const Master = (props: Props) => {
  const { avisos } = useAvisos();

  const avisosRenderizados = useMemo(
    () => avisos.map((aviso) => <Aviso key={aviso.id} id={aviso.id} descricao={aviso.descricao} tipo={aviso.tipo} />),
    [avisos]
  );

  return (
    <div style={{ position: 'relative' }}>
      {avisos.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
            position: 'absolute',
            top: '24px',
            right: '24px',
            zIndex: 1000,
            gap: '16px'
          }}
        >
          {avisosRenderizados}
        </div>
      )}
      <Header />
      <div style={{ padding: '36px 10vw' }}>{props.children}</div>
    </div>
  );
};

export default Master;

const COLORS = {
  erro: '#D92D20',
  sucesso: '#079455',
  alerta: '#DC6803',
  info: '#1570ef'
};

const ICONE = {
  erro: 'error',
  sucesso: 'check_circle',
  alerta: 'warning',
  info: 'info'
};

const Aviso = (props: AvisoType) => {
  const { id, descricao, tipo } = props;
  const [tamanho, setTamanho] = useState(100);

  const { removerAviso } = useAvisos();

  useEffect(() => {
    const duracao = 3000; // 5 segundos
    const steps = 100;
    const interval = duracao / steps; // intervalo de atualização
    let progresso = 100;

    const timer = setInterval(() => {
      progresso -= 1;
      setTamanho(progresso);

      if (progresso <= 0) {
        clearInterval(timer);
        removerAviso(id);
      }
    }, interval);

    return () => clearInterval(timer);
  }, []);

  return (
    <div
      id={id}
      style={{
        flex: 1,
        color: COLORS[tipo],
        borderRadius: '8px',
        backgroundColor: 'var(--background)',
        fontSize: '14px',
        fontWeight: 600,
        userSelect: 'none'
      }}
    >
      <div
        style={{
          padding: '16px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          border: `1px solid ${COLORS[tipo]}`,
          backgroundColor: COLORS[tipo] + '10'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
          onClick={() => removerAviso(id)}
        >
          <span className={`material-symbols-rounded`} style={{ fontSize: '18px', color: COLORS[tipo] }}>
            {ICONE[tipo]}
          </span>
          <span style={{ flex: 1 }}>{descricao}</span>
          <span
            className={`material-symbols-rounded`}
            style={{ fontSize: '12px', color: 'var(--text)', cursor: 'pointer', paddingLeft: '8px' }}
          >
            close
          </span>
        </div>
        <div
          style={{
            height: '2px',
            borderRadius: '8px',
            backgroundColor: COLORS[tipo],
            width: `${tamanho}%`,
            transition: 'width 0.05s linear'
          }}
        />
      </div>
    </div>
  );
};

