import { useEffect, useState } from 'react';
import ComboBox from '../componentes/inputs/ComboBox';
import TextField from '../componentes/inputs/TextField';
import Botao from '../componentes/Botao';
import Switch from '../componentes/switch/Switch';
import MoneyField from '../componentes/inputs/MoneyField';
import NumberField from '../componentes/inputs/NumberField';
import Mapa from './componentes/Mapa';
import TipoAdvogado from '../tipos/Advogado';
import TipoFiltro from '../tipos/Filtro';
import PopUp from '../componentes/popup/PopUp';
import Advogado from './Advogado';

const modal = false;

const Principal = () => {
  const [filtro, setFiltro] = useState<TipoFiltro>({ ordenacao: 'DISTANCIA' });
  const [advogados, setAdvogados] = useState<TipoAdvogado[]>([
    {
      id: 1,
      imagem: require('../imagens/person1.webp'),
      nome: 'Dra. Camila Ferreira',
      areas: ['Direito de Família', 'Inventário'],
      endereco: {
        rua: 'Rua José de Alencar, 123',
        bairro: 'Centro',
        cidade: 'Curitiba'
      },
      distancia_km: 1.2,

      email: 'camile.ferreira@advogado.com.br',
      telefone: '(41) 99999-9999',
      site: 'https://www.camilaferreira.com.br',
      redes_sociais: {
        facebook: 'https://www.facebook.com/camilaferreira',
        instagram: 'https://www.instagram.com/camilaferreira',
        linkedin: 'https://www.linkedin.com/in/camilaferreira',
        twitter: 'https://twitter.com/camilaferreira'
      },
      horario_funcionamento: {
        segunda: '08:00 - 18:00',
        terca: '08:00 - 18:00',
        quarta: '08:00 - 18:00',
        quinta: '08:00 - 18:00',
        sexta: '08:00 - 18:00',
        sabado: '09:00 - 12:00',
        domingo: 'Fechado'
      },

      nota: 4.9,
      reviews: 134,
      disponivel_agora: true,
      atende_online: true,
      preco_medio_consulta: 150,
      lat: -25.429596,
      lng: -49.271272,
      descricao:
        'Advogada especializada em Direito de Família e Sucessões, com ampla experiência em processos de divórcio, guarda, pensão alimentícia, inventários e partilhas.\n\n' +
        'Atua com foco na mediação e na resolução consensual de conflitos, priorizando o diálogo e a celeridade nos processos.\n\n' +
        'Reconhecida pela abordagem empática e pela capacidade de transformar temas sensíveis em soluções jurídicas claras e seguras para seus clientes.\n\n' +
        'Atendimento personalizado, presencial ou online, com foco na confiança e transparência ao longo de toda a demanda.',
      servicos: [
        { nome: 'Divórcio', descricao: 'Acompanhamento completo do processo de divórcio, incluindo acordos e homologações.', preco: 150 },
        { nome: 'Inventário', descricao: 'Assessoria em inventários, partilhas e questões sucessórias.', preco: 200 },
        { nome: 'Pensão Alimentícia', descricao: 'Ajuizamento e acompanhamento de ações de pensão alimentícia.', preco: 120 },
        { nome: 'Guarda de Filhos', descricao: 'Orientação e acompanhamento em ações de guarda e visitação.', preco: 180 },
        { nome: 'Adoção', descricao: 'Assessoria em processos de adoção, incluindo adoção internacional.', preco: 250 },
        {
          nome: 'Reconhecimento de Paternidade',
          descricao: 'Ajuizamento e acompanhamento de ações de reconhecimento de paternidade.',
          preco: 150
        },
        { nome: 'Planejamento Sucessório', descricao: 'Orientação e elaboração de testamentos e planejamento sucessório.', preco: 300 },
        { nome: 'Contratos de União Estável', descricao: 'Elaboração e revisão de contratos de união estável.', preco: 200 },
        { nome: 'Mediação Familiar', descricao: 'Atuação como mediadora em conflitos familiares.', preco: 180 },
        { nome: 'Consultoria Jurídica', descricao: 'Consultoria jurídica em questões de família e sucessões.', preco: 100 }
      ]
    },
    {
      id: 2,
      imagem: require('../imagens/person2.webp'),
      nome: 'Dr. Rafael Mota',
      areas: ['Direito Penal', 'Tribunal do Júri'],
      endereco: {
        rua: 'Av. República Argentina, 456',
        bairro: 'Água Verde',
        cidade: 'Curitiba'
      },
      distancia_km: 3.5,
      nota: 4.6,
      reviews: 89,
      disponivel_agora: false,
      atende_online: false,
      preco_medio_consulta: 250,
      lat: -25.449289,
      lng: -49.287497,
      descricao:
        'Experiente em defesas criminais e atuação em júris populares. Oferece atendimento técnico e estratégico, com linguagem direta ao cliente.'
    },
    {
      id: 3,
      imagem: require('../imagens/person3.webp'),
      nome: 'Dra. Beatriz Sanches',
      areas: ['Direito do Consumidor', 'Direito Civil'],
      endereco: {
        rua: 'Rua XV de Novembro, 789',
        bairro: 'Centro Cívico',
        cidade: 'Curitiba'
      },
      distancia_km: 0.8,
      nota: 4.8,
      reviews: 112,
      disponivel_agora: true,
      atende_online: true,
      preco_medio_consulta: 180,
      lat: -25.416595,
      lng: -49.273003,
      descricao:
        'Atua na defesa do consumidor e ações indenizatórias. Conhecida pela objetividade nos processos e ótimo índice de acordos extrajudiciais.'
    },
    {
      id: 4,
      imagem: require('../imagens/person4.webp'),
      nome: 'Dr. Lucas Almeida',
      areas: ['Direito Trabalhista'],
      endereco: {
        rua: 'Rua Chile, 55',
        bairro: 'Rebouças',
        cidade: 'Curitiba'
      },
      distancia_km: 2.1,
      nota: 3.1,
      reviews: 47,
      disponivel_agora: true,
      atende_online: false,
      preco_medio_consulta: 120,
      lat: -25.448582,
      lng: -49.265201,
      descricao:
        'Especialista em ações trabalhistas individuais e coletivas. Atende principalmente empregados em disputas por verbas rescisórias e reintegrações.'
    },
    {
      id: 5,
      imagem: require('../imagens/person5.webp'),
      nome: 'Dra. Mariana Lopes',
      areas: ['Direito Previdenciário', 'Aposentadorias'],
      endereco: {
        rua: 'Rua Marechal Deodoro, 321',
        bairro: 'Alto da Glória',
        cidade: 'Curitiba'
      },
      distancia_km: 2.9,
      nota: 4.7,
      reviews: 98,
      disponivel_agora: false,
      atende_online: true,
      preco_medio_consulta: 160,
      lat: -25.428774,
      lng: -49.262145,
      descricao:
        'Focada em aposentadorias e revisões de benefícios do INSS. Reconhecida por sua didática no atendimento e eficiência na entrega de resultados.'
    }
  ]);

  const [advogadoSelecionado, setAdvogadoSelecionado] = useState<TipoAdvogado | null>(null);

  const [listaFiltrada, setListaFiltrada] = useState<TipoAdvogado[]>([]);

  const areas = advogados
    .reduce<{ id: string; valor: string }[]>((acc, advogado) => {
      advogado.areas.forEach((area) => {
        if (!acc.some((a) => a.id === area)) {
          acc.push({ id: area, valor: area });
        }
      });
      return acc;
    }, [])
    .sort((a, b) => a.valor.localeCompare(b.valor));

  useEffect(() => {
    setListaFiltrada(advogados);
  }, []);

  const handleLimpar = () => {
    setFiltro((prevFiltro) => ({
      ...prevFiltro,
      area: undefined,
      preco: undefined,
      distancia: undefined,
      atende_online: null,
      pesquisa: ''
    }));

    setListaFiltrada(advogados);
  };

  const handleFiltrar = () => {
    setListaFiltrada(
      advogados.filter((advogado) => {
        const areaMatch = filtro.area ? advogado.areas.includes(filtro.area) : true;
        const precoMatch = filtro.preco ? advogado.preco_medio_consulta <= filtro.preco : true;
        const distanciaMatch = filtro.distancia ? advogado.distancia_km <= filtro.distancia : true;
        const atendeOnlineMatch = filtro.atende_online === true ? advogado.atende_online === filtro.atende_online : true;
        const pesquisaMatch =
          filtro.pesquisa && filtro.pesquisa.length > 0
            ? advogado.nome.toLowerCase().includes(filtro.pesquisa.toLowerCase()) ||
              advogado.areas.some((area) => area.toLowerCase().includes((filtro.pesquisa ?? '').toLowerCase()))
            : true;

        return areaMatch && precoMatch && distanciaMatch && atendeOnlineMatch && pesquisaMatch;
      })
    );
  };

  const handleLike = (id: number) => {
    setAdvogados((prevAdvogados) => prevAdvogados.map((a) => (a.id === id ? { ...a, curtido: !a.curtido } : a)));
  };

  const advogadosOrdenados = listaFiltrada.sort((a, b) => {
    if (filtro.ordenacao === 'DISTANCIA') {
      return a.distancia_km - b.distancia_km;
    } else if (filtro.ordenacao === 'PREÇO') {
      return a.preco_medio_consulta - b.preco_medio_consulta;
    }
    return 0;
  });

  const advogadosExibidos = advogadosOrdenados.slice(0, 10);

  return (
    <>
      {advogadoSelecionado &&
        (modal ? (
          <PopUp onClose={() => setAdvogadoSelecionado(null)}>
            <Advogado dados={advogadoSelecionado} modal />
          </PopUp>
        ) : (
          <Advogado dados={advogadoSelecionado} onClose={() => setAdvogadoSelecionado(null)} />
        ))}
      {(modal || (!modal && !advogadoSelecionado)) && (
        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '32px' }}>
          <div style={{ flex: 0.6, display: 'flex', flexDirection: 'column', gap: '32px' }}>
            <div id="filtros" style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div id="filtroOutor" style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px', justifyContent: 'space-between' }}>
                  <ComboBox
                    placeholder="Área de atuação"
                    options={areas}
                    onChange={(area) => setFiltro({ ...filtro, area })}
                    value={filtro.area}
                    icone="globe"
                    style={{ width: '180px' }}
                  />
                  <MoneyField
                    placeholder="Limite de preço"
                    value={filtro.preco}
                    onChange={(preco) => setFiltro({ ...filtro, preco })}
                    icone="payments"
                    style={{ width: '180px' }}
                  />
                  <NumberField
                    placeholder="Distância em km"
                    value={filtro.distancia}
                    onChange={(distancia) => setFiltro({ ...filtro, distancia })}
                    icone="place"
                    style={{ width: '180px' }}
                  />
                </div>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Switch
                    checked={filtro.atende_online ?? false}
                    onChange={(atende_online) => setFiltro({ ...filtro, atende_online })}
                    label="Atende online?"
                  />
                </div>
              </div>
              <div id="filtroPesquisa" style={{ display: 'flex', gap: '12px' }}>
                <TextField
                  placeholder="Pesquisa..."
                  value={filtro.pesquisa}
                  onChange={(pesquisa) => setFiltro({ ...filtro, pesquisa })}
                  icone="search"
                  style={{ flex: 1 }}
                />
                <Botao titulo="Limpar" onClick={handleLimpar} type="cancel" />
                <Botao titulo="Pesquisar" onClick={handleFiltrar} type="primary" />
              </div>
            </div>
            <div id="ordenacao" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <span style={{ fontSize: '16px', color: 'var(--textSecondary)' }}>
                  Encontramos {advogados.length} advogado{advogados.length > 1 ? 's' : ''}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    borderStartStartRadius: '8px',
                    borderEndStartRadius: '8px',
                    padding: '8px 12px',
                    fontSize: '12px',
                    fontWeight: 500,
                    borderTop: '1px solid var(--border)',
                    borderLeft: '1px solid var(--border)',
                    borderBottom: '1px solid var(--border)',
                    backgroundColor: filtro.ordenacao === 'PREÇO' ? 'var(--border)' : 'var(--background)',
                    cursor: 'pointer'
                  }}
                  onClick={() => setFiltro({ ...filtro, ordenacao: 'PREÇO' })}
                >
                  Ordenar por preço
                </div>
                <div
                  style={{
                    borderStartEndRadius: '8px',
                    borderEndEndRadius: '8px',
                    padding: '8px 12px',
                    fontSize: '12px',
                    fontWeight: 500,
                    border: '1px solid var(--border)',
                    backgroundColor: filtro.ordenacao === 'DISTANCIA' ? 'var(--border)' : 'var(--background)',
                    cursor: 'pointer'
                  }}
                  onClick={() => setFiltro({ ...filtro, ordenacao: 'DISTANCIA' })}
                >
                  Ordenar por distância
                </div>
              </div>
            </div>
            <div
              id="lista"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                paddingRight: '8px',
                overflow: 'auto',
                maxHeight: 'calc(100vh - 328px)',
                borderRadius: '8px'
              }}
            >
              {advogadosOrdenados.map((advogado) => {
                return (
                  <div
                    key={advogado.id}
                    style={{
                      padding: '12px',
                      borderRadius: '8px',
                      backgroundColor: 'var(--background)',
                      border: '1px solid var(--border)',
                      display: 'flex',
                      gap: '16px',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => setAdvogadoSelecionado(advogado)}
                  >
                    <div style={{ width: '100px', height: '100px', borderRadius: '8px', overflow: 'hidden' }}>
                      <img
                        src={advogado.imagem}
                        alt={advogado.nome}
                        style={{
                          height: '100%',
                          borderRadius: '8px'
                        }}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: 1 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                          <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>{advogado.endereco.rua}</span>
                          <span style={{ fontSize: '16px', fontWeight: 600 }}>{advogado.nome}</span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px',
                            width: '36px',
                            height: '34px',
                            border: '1px solid var(--border)',
                            cursor: 'pointer',
                            userSelect: 'none'
                          }}
                          onClick={() => handleLike(advogado.id)}
                        >
                          <span
                            className={advogado.curtido ? 'material-icons' : 'material-symbols-rounded'}
                            style={{ fontSize: '18px', color: advogado.curtido ? 'var(--error)' : 'var(--textSecondary)' }}
                          >
                            favorite
                          </span>
                        </div>
                      </div>
                      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                          {[...Array(5)].map((_, i) => {
                            const nota = Math.round(advogado.nota * 2) / 2;
                            let icon = 'star_border';
                            if (i + 1 <= nota) icon = 'star';
                            else if (i + 0.5 === nota) icon = 'star_half';

                            return (
                              <span key={i} className="material-icons" style={{ fontSize: '18px', color: 'var(--yellow)' }}>
                                {icon}
                              </span>
                            );
                          })}
                        </div>
                        <span style={{ fontSize: '14px' }}>{advogado.nota}</span>
                        <span style={{ fontSize: '12px', color: 'var(--textSecondary)' }}>{advogado.reviews} avaliações</span>
                      </div>

                      <div style={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                          <span style={{ color: 'var(--textSecondary)', display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <span className="material-symbols-rounded" style={{ fontSize: '14px' }}>
                              place
                            </span>
                            {advogado.distancia_km} km
                          </span>
                          <span style={{ color: 'var(--textSecondary)', display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <span className="material-symbols-rounded" style={{ fontSize: '14px' }}>
                              {advogado.atende_online ? 'wifi' : 'home'}
                            </span>
                            {advogado.atende_online ? 'Atende online' : 'Atende presencialmente'}
                          </span>
                        </div>
                        <span style={{ fontSize: '16px', fontWeight: 600 }}>
                          R$ {advogado.preco_medio_consulta}
                          <span style={{ color: 'var(--textSecondary)' }}>/média</span>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            id="mapa"
            style={{
              flex: 0.4,
              width: '100%',
              height: 'calc(100vh - 140px)',
              borderRadius: '8px',
              overflow: 'hidden',
              border: '1px solid var(--border)'
            }}
          >
            <Mapa markers={advogados} />
          </div>
        </div>
      )}
    </>
  );
};

export default Principal;

